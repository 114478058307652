import React,{Suspense} from 'react'
import Loading from './components/Loading';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css'
import {Routes, Route} from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Home = React.lazy(() => import('./pages/Home'));
const About = React.lazy(() => import('./pages/About'));
const WhoWeServe = React.lazy(() => import('./pages/WhoWeServe'));
const EPrescription = React.lazy(() => import('./pages/EPrescription'));
// const DigitalMarketing = React.lazy(() => import('./pages/DigitalMarketing'));
// const ReputationManagement = React.lazy(() => import('./pages/ReputationManagement'));
const EMR = React.lazy(() => import('./pages/EMR'));
const Appointment = React.lazy(() => import('./pages/Appointment'));
const StaffManagement = React.lazy(() => import('./pages/StaffManagement'));
const Contact = React.lazy(() => import('./pages/Contact'));
const RequestDemo = React.lazy(() => import('./pages/RequestDemo'));
const PrivacyPolicy = React.lazy(() => import('./pages/PrivacyPolicy'))
const FAQs = React.lazy(() => import('./pages/Faq'))
const AppDev = React.lazy(() => import('./pages/AppDev'))
const Blogs = React.lazy(() => import('./pages/AllBlogs'))
const Blog = React.lazy(() => import('./pages/Blog'))
// const AppDev2 = React.lazy(() => import('./pages/AppDev'))
const NotFound = React.lazy(() => import('./pages/404'))
 
const App = () => {
    return (
        <>
        <Suspense fallback={<Loading />}>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about-us" element={<About />}/>
                <Route path="/who-we-serve" element = {<WhoWeServe/>} />
                <Route path="/e-prescription-software-dubai" element = {<EPrescription/>} />
                {/* <Route path="/healthcare-digital-marketing-dubai" element = {<DigitalMarketing/>} /> */}
                {/* <Route path="/online-reputation-management-services-dubai" element = {<ReputationManagement/>} /> */}
                <Route path="/emr-solution-in-dubai" element = {<EMR/>} />
                <Route path="/medical-appointment-scheduling-software-dubai" element = {<Appointment/>} />
                <Route path="/medical-staff-management-dubai" element = {<StaffManagement/>} />
                <Route path="/mobile-app-development-company-dubai" element = {<AppDev/>} />
                {/* <Route path="/mobile-app-development-company-dubai" element = {<AppDev2/>} /> */}
                <Route path='/contact-us' element={<Contact />}/>
                <Route path='/request-demo' element={<RequestDemo />}/>
                <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
                <Route path="/faq" element={<FAQs/>} />
                <Route path="/blogs" element={<Blogs/>} />
                <Route path="/blogs/:slug" element={<Blog/>} />
                <Route path="*" element={<NotFound/>}  />
            </Routes>
            <ToastContainer 
                position="bottom-left"
                autoClose={5000}
                />
        </Suspense>
        
        </>
    )
}

export default App
