import React from 'react'
import loadingVideo from '../assets/images/loading.webm';


const Loading = () => {
  return (
    <div>
         <div className="loading-wrapper">
            {/* <span className="loading-dot"></span>
            <div className="loading-dots">
                <span></span>
                <span></span>
                <span></span>
            </div> */}
            <video src={loadingVideo} className="img-fluid" alt="icarepro loading" autoPlay muted loop="true" type="video/webm" />
            </div>
    </div>
  )
}

export default Loading